<template>
  <div>
    <BaseNav></BaseNav>
    <!-- Page content -->
    <div class="page-content">
      <!-- Main content -->
      <div class="content-wrapper">
        <!-- Content area -->
        <div class="content d-flex justify-content-center align-items-center">
          <!-- Login card -->
          <form
            class="login-form"
            ref="login-form"
            @submit.prevent="submitReset"
          >
            <div class="card mb-0 shadow-lg rounded">
              <div class="card-body">
                <div class="text-center mb-3">
                  <i
                    class="
                      icon-reading icon-2x
                      text-slate-300
                      border-slate-300 border-3
                      rounded-round
                      p-3
                      mb-3
                      mt-1
                    "
                  ></i>
                  <h5 class="mb-0">Forgot your password?</h5>
                  <p>Request a password reset link.</p>
                </div>

                <div
                  class="
                    form-group form-group-feedback form-group-feedback-left
                  "
                >
                  <input
                    type="text"
                    v-model="forgotPassword.email"
                    name="email"
                    required
                    class="form-control"
                    placeholder="Email Address"
                  />
                  <div class="form-control-feedback">
                    <i class="icon-user text-muted"></i>
                  </div>
                </div>

                <div class="form-group d-flex align-items-center">
                  <div class="g-recaptcha" data-sitekey="your_site_key"></div>
                </div>

                <div class="form-group">
                  <button
                    type="submit"
                    :disabled="forgotPassword.busy"
                    class="btn bg-primary-400 btn-block"
                  >
                    {{
                      forgotPassword.busy
                        ? "Loading.."
                        : "Get Password Reset Link"
                    }}<i class="icon-circle-right2 ml-2"></i>
                  </button>
                </div>

                <div class="form-group d-flex align-items-center">
                  <router-link :to="{ name: 'Login' }" class=""
                    >Login to your account</router-link
                  >
                </div>

                <div class="form-group text-center text-muted content-divider">
                  <span class="px-2">Don't have an account?</span>
                </div>

                <div class="form-group">
                  <router-link
                    :to="{ name: 'Register' }"
                    class="btn btn-light btn-block"
                    >Sign up</router-link
                  >
                </div>
              </div>
            </div>
          </form>
          <!-- /login card -->
        </div>
        <!-- /content area -->
      </div>
      <!-- /main content -->
    </div>
    <!-- /page content -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState(["auth/is_logged_in", "auth/user"]),
  },
  data() {
    return {
      forgotPassword: new this.$Form({
        email: "",
      }),
    };
  },
  methods: {
    ...mapActions("auth", ["doSendPasswordResetLink", "doGetAuthUser"]),
    submitReset() {
      this.doSendPasswordResetLink(this.forgotPassword)
        .then(() => {
          this.$notify({
            title: "Success",
            text: "Check your email for a password reset link.",
            style: "success",
          });
        })
        .catch((err) => {
          console.log("err", err);
          this.$notify({
            title: "Error",
            text:
              err.response && err.response.data
                ? err.response.data.Message
                : "Error",
            style: "danger",
          });
        });
    },
  },
  mounted() {
    this.$parent.$emit("loader:hide");
  },
};
</script>


